/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



          (function () {
              window.onpageshow = function(event) {
                  if (event.persisted) {
                      window.location.reload();
                  }
              };
          })();

          window.addEventListener('load', AOS.refresh());



        window.addEventListener("beforeunload", function () {
          document.body.classList.add("animate-out");
        });










        // add custom media query sizes for Foundation Interchange
        Foundation.Interchange.SPECIAL_QUERIES.smallretina = 'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.mediumretina = 'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.largeretina = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.xlargeretina = 'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

        // init Foundation JavaScript
        $(document).foundation();

          if (navigator.userAgent.match(/Trident.*rv\:11\./)) {

            $("header, main").hide();
              $(".old-browser-message").removeClass('hidden');
          }

        // add plz pattern for Foundation Abide
        Foundation.Abide.defaults.patterns.plz = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

          function getSliderSettings() {
              return {
                  slidesToShow: 1,
                  arrows: false,
                  fade: false,
                  speed: 1500,
                  dots: false,
                  autoplay: true,
                  pauseOnHover:false,
                  pauseOnFocus: false,
                  centerMode: true,
                  adaptiveHeight: true
              }

          }

          function getSliderSettings_fade() {
              return {
                  slidesToShow: 1,
                  arrows: false,
                  fade: true,
                  speed: 2000,
                  cssEase: 'linear',
                  dots: false,
                  autoplay: true,
                  pauseOnHover:false,
                  pauseOnFocus: false,
                  centerMode: true,
                  adaptiveHeight: true
              }

          }

          function getSliderSettings_fade_ease() {
              return {
                  slidesToShow: 1,
                  arrows: false,
                  fade: true,
                  speed: 900,
                  infinite: true,
                  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                  dots: false,
                  autoplay: true,
                  pauseOnHover:false,
                  centerMode: true,
                  adaptiveHeight: true
              }

          }







          $('.slider-default').slick(getSliderSettings());

          $('.slider-default-fade').slick(getSliderSettings_fade());

          $('.slider-default-fade-ease').slick(getSliderSettings_fade_ease());

          $('.slider-default-fade-scale').slick(getSliderSettings_fade());

          $('.slider-default-swipe-scale').slick(getSliderSettings_fade());




          ScrollOut({
              threshhold: 0.5,
              cssProps: {
                  visibleY: true
              }
          })

         // document.body.classList.remove("animate-out");

        $(window ).load(function() {
          AOS.init({
            duration: 1200,
          });
        });



        var flag = false;
  $(window).scroll(function () {
    if (flag === false) {
    AOS.init({
      duration: 1200,
    });
      flag = true;
    }

  });

          autosize();
          function autosize(){
              var text = $('textarea');

              text.each(function(){
                  $(this).attr('rows',1);
                  resize($(this));
              });

              text.on('input', function(){
                  resize($(this));
              });

              function resize ($text) {
                  $text.css('height', 'auto');
                  $text.css('height', $text[0].scrollHeight+'px');
              }
          }



        /*  $(".arrow-down__kollektion").on('click', function (event) {
              $('html, body').addClass('unsmooth');
              $('html, body').animate({scrollTop: $("#product-85").offset().top}, 700);
                  setTimeout(function(){
                      $('html, body').removeClass('unsmooth');
                  }, 701);
          });*/

          var browserZoomLevel = Math.round(window.devicePixelRatio * 100);


          $('html').addClass('zoom-' + browserZoomLevel);

        $('.single_add_to_cart_button').on('click', function (event) {

          if ($(this).hasClass('disabled')) {
           event.preventDefault();
            $(".cart-choose-overlay").addClass("active");
            $("body").addClass("locked");
            return false;

          }
        //  return false;
        });








        var c, currentScrollTop = 0,
            navbar = $('.nav');



        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = 0;
        $(window).scroll(function(event){
          didScroll = true;
        });

        setInterval(function() {
          if (didScroll) {
            hasScrolled();
            didScroll = false;
          }
        }, 250);


        function hasScrolled() {
          var st = $(this).scrollTop();

          // Make sure they scroll more than delta
          if(Math.abs(lastScrollTop - st) <= delta)
            return;

          // If they scrolled down and are past the navbar, add class .nav-up.
          // This is necessary so you never see what is "behind" the navbar.
          if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('nav ul .menu-item, .cart-item, .logo-mobile').addClass('inactive');
          } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
              $('nav ul .menu-item, .cart-item, .logo-mobile').removeClass('inactive');
            }
          }

          lastScrollTop = st;
        }




          // Check if the URL contains '/checkout'
          if (window.location.href.includes('/checkout')) {

              // Find the element with the class '.woocommerce-shipping-totals th'


              // Check if the element exists


                  setTimeout(function(){
                      var shippingTotalTh = document.querySelector('.woocommerce-shipping-totals th');

                      // Check if the <th> element exists
                      if (shippingTotalTh) {
                          // Create a new table row element
                          var newRow = document.createElement('td');
                          newRow.classList.add('hide-mobile');


                          // Insert the new row after the <th> element
                          shippingTotalTh.parentNode.insertBefore(newRow, shippingTotalTh.nextSibling);
                      }
                  }, 1000);

                  // Create a new table row element


          }


     //    $(".variable-item").removeClass('variable-item');
     //   $(".image-variable-item").removeAttr( "data-wvstooltip" );
    //    $(".image-variable-item").removeAttr( "wvstooltip" );
     //     $(".image-variable-item").removeAttr( "tabindex" );
     //     $(".image-variable-item").removeAttr( "role" );
     //     $(".image-variable-item").removeAttr( "aria-checked" );
          $('.product').trigger('click');


          $(".contact-section__right .accordion-title").click(function(){
              $(".contact-accordion-content").toggleClass('active');
              $(this).toggleClass('active');
          });


        $(".more-trigger").click(function(){
          $(".more-content").addClass('active');
          $(this).addClass('active');
        });


        ///


          $("#product-805 .image-variable-item").click(function(){
              var this_item = $(this);
              var closest = $(this).closest('.summary').find(".ab_price");
              $(closest).addClass("selected");
              $(closest).empty();
              setTimeout(function(){
                  var newprice = $('#product-805 .single_variation_wrap .woocommerce-Price-amount');
                  $(closest).append(newprice.html());
              }, 500);
          });

          $("#product-806 .image-variable-item").click(function(){
              var this_item = $(this);
              var closest = $(this).closest('.summary').find(".ab_price");
              $(closest).addClass("selected");
              $(closest).empty();
              setTimeout(function(){
                  var newprice = $('#product-806 .single_variation_wrap .woocommerce-Price-amount');
                  $(closest).append(newprice.html());
              }, 500);
          });

          $("#product-807 .image-variable-item").click(function(){
              var this_item = $(this);
              var closest = $(this).closest('.summary').find(".ab_price");
              $(closest).addClass("selected");
              $(closest).empty();
              setTimeout(function(){
                  var newprice = $('#product-807 .single_variation_wrap .woocommerce-Price-amount');
                  $(closest).append(newprice.html());
              }, 500);
          });

          $("#product-808 .image-variable-item").click(function(){
              var this_item = $(this);
              var closest = $(this).closest('.summary').find(".ab_price");
              $(closest).addClass("selected");
              $(closest).empty();
              setTimeout(function(){
                  var newprice = $('#product-808 .single_variation_wrap .woocommerce-Price-amount');
                  $(closest).append(newprice.html());
              }, 500);
          });

          $("#product-810 .image-variable-item").click(function(){
              var this_item = $(this);
              var closest = $(this).closest('.summary').find(".ab_price");
              $(closest).addClass("selected");
              $(closest).empty();
              setTimeout(function(){
                  var newprice = $('#product-810 .single_variation_wrap .woocommerce-Price-amount');
                  $(closest).append(newprice.html());
              }, 500);
          });


          ///



                $("#product-85 .image-variable-item ").click(function(){
                    var this_item = $(this);
                  var closest = $(this).closest('.summary').find(".ab_price");
                 $(closest).addClass("selected");
                    $(closest).empty();
                    setTimeout(function(){
                        var newprice = $('#product-85 .single_variation_wrap .woocommerce-Price-amount');
                        $(closest).append(newprice.html());
                    }, 500);
                });

          $("#product-109 .image-variable-item").click(function(){
              var this_item = $(this);
              var closest = $(this).closest('.summary').find(".ab_price");
              $(closest).addClass("selected");
              $(closest).empty();
              setTimeout(function(){
                  var newprice = $('#product-109 .single_variation_wrap .woocommerce-Price-amount');
                  $(closest).append(newprice.html());
              }, 500);
          });

          $("#product-36 .image-variable-item").click(function(){
              var this_item = $(this);
              var closest = $(this).closest('.summary').find(".ab_price");
              $(closest).addClass("selected");
              $(closest).empty();
              setTimeout(function(){
                  var newprice = $('#product-36 .single_variation_wrap .woocommerce-Price-amount');
                  $(closest).append(newprice.html());
              }, 500);
          });



            $(".mobile-menu-trigger, .close-wrapper").click(function(){
                                    $(".mobile-menu__wrapper, .st0, .mobile-menu-trigger, .mobile-menu__wrapper svg").toggleClass("active");
                                  });



          var scrollPosition = $("body, html").scrollTop()
          if (scrollPosition == 0){
              $('nav, .mobile-menu-trigger').removeClass('gold');
              $('nav, .mobile-menu-trigger').addClass('dark');
              $('nav, .mobile-menu-trigger').removeClass('light');
          }




                $(window).scroll(function() {


                    if (window.innerWidth >= 100) {
                        var scrollTop = $(this).scrollTop();

                        $('#swiss-made').each(function () {
                            var topDistance = $(this).offset().top;

                            if ((topDistance - 100) < scrollTop && (topDistance + window.innerHeight) > scrollTop) {
                                setTimeout(function () {
                                    $('#swiss-made .over-text').addClass('inactive');
                                }, 4000);
                            } else {
                                $('#swiss-made .over-text').removeClass('inactive');

                            }
                        });
                        $('#kollektion').each(function () {
                            var topDistance = $(this).offset().top;

                            if ((topDistance - 100) < scrollTop && (topDistance + window.innerHeight) > scrollTop) {
                                setTimeout(function () {
                                    $('#kollektion .over-text').addClass('inactive');
                                }, 4000);
                            } else {
                                $('#kollektion .over-text').removeClass('inactive');

                            }
                        });
                        $('#naturholz').each(function () {
                            var topDistance = $(this).offset().top;

                            if ((topDistance - 100) < scrollTop && (topDistance + window.innerHeight) > scrollTop) {
                                setTimeout(function () {
                                    $('#naturholz .over-text').addClass('inactive');
                                }, 4000);
                            } else {
                                $('#naturholz .over-text').removeClass('inactive');

                            }
                        });



                        $('#monocle').each(function () {
                            var topDistance = $(this).offset().top;

                            if ((topDistance - 100) < scrollTop && (topDistance + window.innerHeight) > scrollTop) {
                                setTimeout(function () {
                                    $('#monocle .over-text').addClass('inactive');
                                }, 4000);
                            } else {
                                $('#monocle .over-text').removeClass('inactive');

                            }
                        });

                        $('#aussteller').each(function () {
                            var topDistance = $(this).offset().top;

                            if ((topDistance - 100) < scrollTop && (topDistance + window.innerHeight) > scrollTop) {
                                setTimeout(function () {
                                    $('#aussteller .over-text').addClass('inactive');
                                }, 4000);
                            } else {
                                $('#aussteller .over-text').removeClass('inactive');

                            }
                        });

                        $('#holzpflege').each(function () {
                            var topDistance = $(this).offset().top;

                            if ((topDistance - 100) < scrollTop && (topDistance + window.innerHeight) > scrollTop) {
                                setTimeout(function () {
                                    $('#holzpflege .over-text').addClass('inactive');
                                }, 4000);
                            } else {
                                $('#holzpflege .over-text').removeClass('inactive');

                            }
                        });
                    }


                  $( ".color" ).each(function() {

                    if ( $(window).scrollTop() >= $(this).offset().top - 40 ) {

                      if ($(this).hasClass('light-section')) {
                        $('nav, .mobile-menu-trigger').addClass('light');
                        $('nav, .mobile-menu-trigger').removeClass('dark');
                          $('nav, .mobile-menu-trigger').removeClass('gold');
                      }

                        else if ($(this).hasClass('gold-section')) {
                            $('nav, .mobile-menu-trigger').addClass('gold');
                            $('nav, .mobile-menu-trigger').removeClass('dark');
                          $('nav, .mobile-menu-trigger').removeClass('light');
                        }

                      else {
                        $('nav, .mobile-menu-trigger').addClass('dark');
                        $('nav, .mobile-menu-trigger').removeClass('light');
                          $('nav, .mobile-menu-trigger').removeClass('gold');
                      }


                    }

                  });

                });

                $(".close-cart-overlay-button, .close-cart-overlay, .cart-overlay, .cart-choose-overlay").click(function(e) {
                  $(".cart-overlay, .cart-choose-overlay").removeClass('active');
                  $("body").removeClass("locked");
                });


                $("header .menu-item a").click(function(e) {

                 // e.pre
                  // ventDefault();
                  $(".mobile-menu__wrapper, .st0, .mobile-menu-trigger").removeClass("active");
                  var aid = $(this).attr("href");
                });



                ///


          $("#product-805 .variable-item").click(function(e) {
              $("#product-805 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });

          $("#product-806 .variable-item").click(function(e) {
              $("#product-806 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });


          $("#product-807 .variable-item").click(function(e) {
              $("#product-807 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });

          $("#product-808 .variable-item").click(function(e) {
              $("#product-808 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });

          $("#product-809 .variable-item").click(function(e) {
              $("#product-809 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });

          $("#product-810 .variable-item").click(function(e) {
              $("#product-810 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });


          ///

          $("#product-109 .variable-item").click(function(e) {
              $("#product-109 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });

          $("#product-85 .variable-item").click(function(e) {
              $("#product-85 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });

          $("#product-36 .variable-item").click(function(e) {
              $("#product-36 .woocommerce-product-gallery--columns-6").addClass('hide-for-now');
          });




              $('.single_add_to_cart_button').on('click', function(e){


                  e.preventDefault();

                  if ($(this).hasClass('disabled')) {

                  }
                  else {
                      $(".cart-overlay").addClass("active");
                      $("body").addClass("locked");
                  }



                  $thisbutton = $(this),

                      $form = $thisbutton.closest('form.cart'),

                      id = $thisbutton.val(),

                      product_qty = $form.find('input[name=quantity]').val() || 1,

                      product_id = $form.find('input[name=product_id]').val() || id,

                      variation_id = $form.find('input[name=variation_id]').val() || 0;

                  var data = {

                      action: 'ql_woocommerce_ajax_add_to_cart',

                      product_id: product_id,

                      product_sku: '',

                      quantity: product_qty,

                      variation_id: variation_id,

                  };

                  $.ajax({

                      type: 'post',

                      url: wc_add_to_cart_params.ajax_url,

                      data: data,

                      beforeSend: function (response) {

                          $thisbutton.removeClass('added').addClass('loading');

                      },

                      complete: function (response) {

                          $thisbutton.addClass('added').removeClass('loading');

                      },

                      success: function (response) {

                          if (response.error & response.product_url) {

                              window.location = response.product_url;

                              return;

                          } else {

                              $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);

                          }

                      },

                  });

              });



/*
                $('form.cart').on('submit', function(e) {
                  e.preventDefault();

                  $(".cart-overlay").addClass("active");
                  $("body").addClass("locked");



                  var form = $(this);
                  form.block({ message: null, overlayCSS: { background: '#fff', opacity: 0.6 } });

                  var formData = new FormData(form.context);
                  formData.append('add-to-cart', form.find('[name=add-to-cart]').val() );

                  // Ajax action.
                  $.ajax({
                    url: wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'ace_add_to_cart' ),
                    data: formData,
                      action: 'woocommerce_ajax_add_to_cart',
                    type: 'POST',
                    processData: false,
                    contentType: false,
                    complete: function( response ) {
                      response = response.responseJSON;

                      if ( ! response ) {
                        return;
                      }

                      if ( response.error && response.product_url ) {
                        window.location = response.product_url;
                        return;
                      }

                      // Redirect to cart option
                      if ( wc_add_to_cart_params.cart_redirect_after_add === 'yes' ) {
                        window.location = wc_add_to_cart_params.cart_url;
                        return;
                      }

                      var $thisbutton = form.find('.single_add_to_cart_button'); //
        //						var $thisbutton = null; // uncomment this if you don't want the 'View cart' button

                      // Trigger event so themes can refresh other areas.
                      $( document.body ).trigger( 'added_to_cart', [ response.fragments, response.cart_hash, $thisbutton ] );




                      // Remove existing notices
                      $( '.woocommerce-error, .woocommerce-message, .woocommerce-info' ).remove();
                      $(document.body).trigger('wc_fragment_refresh');
                      $( '.mini-cart').removeClass("hidden");


                      // Add new notices
                      form.closest('.product').before(response.fragments.notices_html)

                      form.unblock();
                    }
                  });
                });
                */



          $('#ship-to-different-address input:checkbox').change(function(){
              if($(this).is(":checked")) {
                  $('#ship-to-different-address').addClass("checked");
              } else {
                  $('#ship-to-different-address').removeClass("checked");
              }
          });



         if(window.location.hash) {
             $('nav ul .menu-item, .cart-item, .logo-mobile').addClass('inactive-anchor-off');
             $('nav ul .menu-item, .cart-item, .logo-mobile').removeClass('inactive');


             setTimeout(function () {
                 $('nav ul .menu-item, .cart-item, .logo-mobile').removeClass('inactive');
                 $('nav ul .menu-item, .cart-item, .logo-mobile').removeClass('inactive-anchor-off');

             }, 1001);
         }
















          /*
          $('form.cart').on('submit', function(e) {
            e.preventDefault();

            var form = $(this);
            form.block({ message: null, overlayCSS: { background: '#fff', opacity: 0.6 } });

            var formData = new FormData(form.context);
            formData.append('add-to-cart', form.find('[name=add-to-cart]').val() );

            // Ajax action.
            $.ajax({
              url: wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'ace_add_to_cart' ),
              data: formData,
              type: 'POST',
              processData: false,
              contentType: false,
              complete: function( response ) {
                response = response.responseJSON;

                if ( ! response ) {
                  return;
                }

                if ( response.error && response.product_url ) {
                  window.location = response.product_url;
                  return;
                }

                // Redirect to cart option
                if ( wc_add_to_cart_params.cart_redirect_after_add === 'yes' ) {
                  window.location = wc_add_to_cart_params.cart_url;
                  return;
                }

                var $thisbutton = form.find('.single_add_to_cart_button'); //
  //						var $thisbutton = null; // uncomment this if you don't want the 'View cart' button


                // Trigger event so themes can refresh other areas.
                $( document.body ).trigger( 'added_to_cart', [ response.fragments, response.cart_hash, $thisbutton ] );

                var wp_ajax_url="http://saschakrischock.com/dev/wordpress/lesbois/wp-admin/admin-ajax.php";
                var data = {
                  action: 'getCheckoutPageContent',
                  product_id: 36,
                  quantity: -1
                };







                jQuery.post( wp_ajax_url, data, function(content) {
                  jQuery("#checkOutPageContent").html(content);
                  $("#checkOutPageContent").addClass('active');

                });




                // Remove existing notices
                $( '.woocommerce-error, .woocommerce-message, .woocommerce-info' ).remove();
                $(document.body).trigger('wc_fragment_refresh');
                $( '.mini-cart').removeClass("hidden");


                // Add new notices
                form.closest('.product').before(response.fragments.notices_html)

                form.unblock();
              }
            });
          });

  */



        // fire initial resize
        $(window).trigger('resize');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      },
      resize: function() {
















      },
      windowload: function() {
        //console.log('loaded');
        $('html').addClass('window-loaded');

          setTimeout(function () {
              $('#philosophie').addClass('active');
          }, 5000);
          $(window).on('scroll', function () {
              if ($(this).scrollTop() >= 1 ) {
                  $('#philosophie').removeClass('active');

              } else {
                  setTimeout(function () {
                      $('#philosophie').addClass('active');
                  }, 5000);
              }
          });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      },
      resize: function() {

      },
      windowload: function() {

      }
    },
    'page_template_template_layouts': {
      init: function() {

      },
      finalize: function() {

      },
      resize: function() {

      },
      windowload: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        //UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      //UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    },
    windowLoadEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, 'windowload');
      });

      UTIL.fire('common','windowload');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on("load",UTIL.windowLoadEvents);

  // Resize Events
  var trackWindowWidth = $(window).width();

  $(window).resize(function (trackResizeEvent) {
    //console.log(!trackResizeEvent.isTrigger ? ' resize' : ' triggerd');

    // Check window width has actually changed and it's not just iOS triggering a resize event on scroll || the resize event was caused by $(window).trigger('resize');
    if (($(window).width() !== trackWindowWidth) || trackResizeEvent.isTrigger) {
      // Update the window width for next time
      trackWindowWidth = $(window).width();

      UTIL.resizeEvents();
    }
  });


})(jQuery); // Fully reference jQuery after this point.